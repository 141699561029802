import getData from "@/__main__/get-data.mjs";
import { getAllChampionsStats } from "@/game-lol/fetches/lol-fetch-champions-data.mjs";
import LolProbuildMatches from "@/game-lol/models/lol-probuild-matches.mjs";
import LolProbuildTeams from "@/game-lol/models/lol-probuild-teams.mjs";
import * as API from "@/game-lol/utils/api.mjs";
import { getAllPatchesForChampions } from "@/game-lol/utils/util.mjs";

export default async function fetchData() {
  const patches = getAllPatchesForChampions();
  const promises = [];

  const fetchStats = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolveFetchStats) => {
      const searchParams = new URLSearchParams();

      const currentPatchStats = await getAllChampionsStats(
        searchParams,
        "overview",
        false,
        { skipSafetyCheck: true },
      );

      const currentPatch = currentPatchStats?.[0]?.patch;

      searchParams.set("patch", patches[patches.indexOf(currentPatch) + 1]);
      await getAllChampionsStats(searchParams, "overview", false, {
        skipSafetyCheck: true,
      });
      resolveFetchStats();
    });
  };

  promises.push(fetchStats());

  promises.push(
    getData(
      API.getProbuildTeams(),
      LolProbuildTeams,
      ["lol", "proBuildTeams"],
      {
        shouldFetchIfPathExists: false,
      },
    ),
  );

  promises.push(
    getData(
      API.getProMatchHistory({
        teamIds: [],
        first: 15,
      }),
      LolProbuildMatches,
      ["lol", "proBuildMatchlist"],
      {
        shouldFetchIfPathExists: true,
      },
    ),
  );

  await Promise.all(promises);
}
